<template>
    <div>
        <div v-if="checked">
            <div class="questionnaire-top">
                <div class="questionnaire-title">{{datas.title}}</div>
                <div class="questionnaire-is-reply" v-if="datas.is_reply || datas.is_closed == 1">{{datas.is_closed === 0 ? '该问卷您已提交过' : '该问卷已失效' }}</div>
            </div>
            <div class="questionnaire-descr">
                <div class="description" v-html="datas.descr"></div>
            </div>
            <div class="question-view">
                <div class="question-field" v-for="(x,index) in datas.questions" :key="index">
                    <div class="question-field-label">
                        {{index+1}}.{{x.title}}<span class="red" v-if="x.required == 1">*</span>
                    </div>
                    <div v-if="x.type == 3">
                        <van-field :disabled="datas.is_reply == 1 || datas.is_closed == 1" v-model="x.val" class="question-field-input" :placeholder="x.tips"/>
                    </div>
                    <div v-if="x.type == 1">
                        <div v-if="x.dimensions.length === 0">
                            <van-radio-group v-model="x.val" direction="horizontal">
                                <van-radio :disabled="datas.is_reply == 1 || datas.is_closed == 1" :name="idxs" v-for="(y,idxs) in x.opts" :key="idxs">{{y.name}}</van-radio>
                            </van-radio-group>
                        </div>

                        <div v-if="x.dimensions.length > 0">
                            <table class="matrix-rating" cellspacing="0">
                                <tbody>
                                    <tr class="trlabel">
                                        <th style="min-width: .7rem;"></th>
                                        <th v-for="(y,idx) in x.opts" :key="idx">{{y.name}}</th>
                                    </tr>
                                </tbody>
                                    <tbody v-for="(z,idxz) in x.dimensions" :key="idxz">
                                        <tr>
                                            <td>{{z.name}}</td>
                                            <td v-for="(y,idxs) in x.opts" :key="idxs">
                                                <van-radio :disabled="datas.is_reply == 1 || datas.is_closed == 1" v-model="z.val" :name="idxs"></van-radio>
                                            </td>
                                        </tr>
                                    </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <div class="height-20"></div>
            <van-button class="send-btn" type="info" :disabled="disabled" block @click="onSubmit" v-if="datas.is_reply === 0 && datas.is_closed === 0">提交</van-button>
        </div>
        <div v-if="!checked">
            <van-cell-group>
                <van-field label="手机号" v-model="datas.mobile" />
                <van-field
                  v-model="code"
                  center
                  clearable
                  label="短信验证码"
                  placeholder="请输入短信验证码"
                >
                  <template #button>
                    <van-button size="small" :disabled="seconds != '' && seconds != 0" type="primary" @click="getSms()">发送验证码{{seconds ? '('+seconds+')' : ''}}</van-button>
                    <van-count-down style="display: none;" ref="countDown" :time="time" @change="changeTime" :auto-start="start" format="ss"></van-count-down>
                  </template>
                </van-field>
            </van-cell-group>
            <van-button class="send-btn" type="info" block @click="checkSms()">下一步</van-button>
        </div>
    </div>
</template>
<script>
import {questionnaire,submit,sms,smscheck} from "@/api/customer";
import {ticket, shareTitle, shareUrl, shareImg, shareDesc, commonShare} from "@/api/wxshare";
export default {
    name:'QuestionnaireSms',
    data() {
        return {
            params:this.$route.params,
            datas:{},
            disabled:false,
            checked:false,
            code:'',
            time:0,
            seconds:'',
            start:false
        }
    },
    created() {
        let _this = this;
        _this.updateShare();
        _this.getQuestionnaire();
    },
    methods: {
        changeTime(times) {
            let _this = this;
            // if (times.seconds == 0) _this.start = false;
            _this.seconds = times.seconds;
        },
        async getSms() {
            let _this = this;
            const res = await sms({uuid:_this.params.uuid,mobile:_this.datas.mobile});
            if (res.code == 1) {
                this.$refs.countDown.reset();
                _this.time = res.data.time;
                _this.start = true;
            }
        },
        async checkSms() {
            let _this = this;
            const res = await smscheck({uuid:_this.params.uuid,mobile:_this.datas.mobile,code:_this.code});
            if (res.code == 1) {
                _this.checked = true;
            }
        },
        async updateShare() {
            let _this = this;
            const res = await ticket({url:window.location.href});
            if (res.code == 1) {
                commonShare(res.data, shareTitle, window.location.href, shareImg, '天华客户满意度调查问卷')
            }
        },
        async getQuestionnaire() {
            let _this = this;
            const res = await questionnaire({uuid:_this.params.uuid});
            if (res.code == 1) {
                _this.datas = res.data;
            }
        },
        async onSubmit() {
            let _this = this;
            _this.disabled = true;
            const res = await submit({uuid:_this.params.uuid,datas:_this.datas,code:_this.code,sms:1});
            if (res.code == 1) {
                this.$toast.success(res.msg);
                _this.datas.is_reply = 1;
            } else {
                _this.disabled = false;
            }
        },
    },
}    
</script>